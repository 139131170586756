import { AxiosResponse } from "axios";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { PromoCodeForm } from "../forms/PromoCodeForm";
import { PromoCodeType } from "../models/Cart";
import { Ticket as TicketModel } from "../models/Tickets";
import { getTickets } from "../queries";
import { State } from "../store/store";
import { Error } from "./Error";
import { HoldCountdown } from "./HoldCountdown";
import { Ticket } from "./Ticket";
import { getFeatureFlag } from "../utils/feature-flags";
import { Card, CardContent } from "./ui/card";
import { cn } from "@/lib/utils";
import { Button } from "./ui/button";
import { ChevronRightIcon } from "@heroicons/react/20/solid";
import {
  formatNumberToMoney,
  getCartFees,
  getCartSubTotal,
  getNumberOfItemsInCart,
} from "@/utils/cart";
import { ShoppingCartIcon } from "@heroicons/react/24/outline";
import { cartActions } from "@/store/cart";
import { CartStep } from "@/constants";
import { CartNav } from "./portal/CartNav";

export const Tickets = () => {
  const dispatch = useDispatch();
  const { isLoading, data } = useQuery<
    AxiosResponse<Array<TicketModel>>,
    Error
  >("tickets", getTickets);
  // const hiddenTicket = useSelector((state: State) => state.cart.hiddenTicket);
  const appliedPromoCode = useSelector(
    (state: State) => state.cart.appliedPromoCode
  );
  const cartError = useSelector((state: State) => state.cart.cartError);
  const event = useSelector((state: State) => state.cart.event);
  const portalNavEnabled = getFeatureFlag("PORTAL_NAV_ENABLED");
  const promoCodeOpen = useSelector((state: State) => state.cart.promoCodeOpen);

  const cart = useSelector((state: State) => state.cart.cart);
  const addonCart = useSelector((state: State) => state.cart.addonCart);
  const containsAddons = useSelector(
    (state: State) => state.cart.containsAddons
  );

  return (
    <>
      <div className="sm:mx-auto sm:w-full sm:max-w-xl">
        {/* If there is a ticket in the cart */}
        {data && data.data && (
          <CartNav
            cart={cart}
            addonCart={addonCart}
            leftContent={
              <Button
                size="sm"
                variant="outline"
                className="text-primary"
                onClick={() =>
                  dispatch(
                    cartActions.SetPromoCodeOpen({ promoCodeOpen: true })
                  )
                }
              >
                Enter code
              </Button>
            }
            rightContent={
              cart.length > 0 ? (
                <Button
                  size="sm"
                  variant="outline"
                  className="text-primary"
                  onClick={() =>
                    dispatch(
                      cartActions.SetStep({
                        step: containsAddons
                          ? CartStep.ADDONS
                          : CartStep.CHECKOUT,
                      })
                    )
                  }
                >
                  {containsAddons ? "Addons" : "Checkout"}
                  <ChevronRightIcon
                    className="h-5 w-5 -mr-2"
                    aria-hidden="true"
                  />
                </Button>
              ) : null
            }
          />
        )}

        {/* Promo Code */}
        {promoCodeOpen && <PromoCodeForm />}

        {/* If it's a REVEAL promo code */}
        {appliedPromoCode?.ticket &&
          appliedPromoCode.codeType === PromoCodeType.REVEAL && (
            <ul>
              <div className="border-b border-gray-200 dark:border-white/10 mb-6">
                <p className="text-sm font-medium text-gray-700 dark:text-white mb-2">
                  Unlocked with Code
                </p>
                <Ticket ticket={appliedPromoCode.ticket} />
              </div>
            </ul>
          )}

        {/* Cart Errors */}
        {cartError && (
          <div className="mb-4">
            <Error error={cartError} />
          </div>
        )}

        <div className="pt-1">
          {data && data.data ? (
            <>
              <ul role="list">
                {data.data.map((ticket: TicketModel) => (
                  <Ticket key={ticket.uuid} ticket={ticket} />
                ))}
              </ul>
            </>
          ) : (
            <div className="flex items-center justify-center bg-blue-500 mt-[28rem]">
              <svg
                className="animate-spin h-14 w-14 text-blue-200 -mt-20"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
