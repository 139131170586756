import { useState } from "react";
import { Addon } from "../../models/Tickets";
import { AddonQuantity } from "./AddonQuantity";

interface Props {
  addon: Addon;
}

export const AddonItem = ({ addon }: Props) => {
  // State to manage the visibility of the full description
  const [showFullDescription, setShowFullDescription] = useState(false);

  // Toggle function to show/hide the full description
  const toggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };

  // Function to render the description
  const renderDescription = () => {
    if (addon.description) {
      if (addon.description.length > 50 && !showFullDescription) {
        return (
          <>
            {`${addon.description.substring(0, 100)}... `}
            <button
              onClick={toggleDescription}
              className="dark:text-white underline"
            >
              more info
            </button>
          </>
        );
      }
      return addon.description;
    }
  };

  return (
    <li
      key={addon.uuid}
      className={`list-none p-5 border dark:border-white/5 shadow-sm mb-6 rounded-lg dark:bg-dark-secondary ${
        false ? "border-primary dark:border-indigo-600" : ""
      }`}
    >
      <div className="sm:flex items-center">
        <div>
          <h1 className="font-semibold dark:text-white">{addon.name}</h1>
          <p className="font- text-sm text-muted-foreground dark:text-gray-400">
            &euro;{addon.price} + &euro;{addon.fee} service fee
          </p>
        </div>
        <div className="flex-1 flex sm:justify-end mt-3 sm:mt-0">
          {addon.isSoldOut ? (
            <span className="inline-flex items-center rounded-full bg-gray-100 px-3 py-0.5 text-sm font-medium text-gray-800 text-center">
              Sold Out
            </span>
          ) : (
            <AddonQuantity addon={addon} />
          )}
        </div>
      </div>
      {addon.description && (
        <p className="text-sm text-gray-500 mt-3">{renderDescription()}</p>
      )}
    </li>
  );
};
